.deletable-image-container {
  .delete-button {
    visibility: hidden;
    position: absolute;
    right: 22px;
    top: 15px;
  }
  &:hover {
    .delete-button {
      visibility: visible;
    }
  }
  @media (pointer: coarse), (pointer: none) {
    .delete-button {
      visibility: visible;
    }
  }
  .max-size-250px {
    max-width: 250px;
    max-height: 250px;
    margin-bottom: 1.5rem;
  }
}

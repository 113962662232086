@import 'styles/custom/_variables';

.btn-primary {
  background-color: $fmolhs-dark-blue;
  border-color: $fmolhs-dark-blue;
}

.btn-primary:disabled {
  background-color: $fmolhs-dark-blue;
  border-color: $fmolhs-dark-blue;
}

.btn-primary:hover {
  background-color: $fmolhs-dark-blue-active;
  border-color: $fmolhs-dark-blue-active;
}

.btn-primary:focus {
  background-color: $fmolhs-dark-blue;
  border-color: $fmolhs-dark-blue;
  box-shadow: 0 0 0 0.2rem rgb(2 88 158 / 50%);
}

.btn-primary:active {
  background-color: $fmolhs-dark-blue-active !important;
  border-color: $fmolhs-dark-blue-active !important;
}

.btn-primary:active:focus {
  background-color: $fmolhs-dark-blue-active !important;
  border-color: $fmolhs-dark-blue-active !important;
  box-shadow: 0 0 0 0.2rem rgb(2 88 158 / 50%) !important;
}

.btn-link {
  color: $fmolhs-dark-blue;
}

.btn-link:hover {
  color: $fmolhs-dark-blue;
}

.btn-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(2 88 158 / 50%) !important;
}

//Create new overrides and import them here as you need them.
@import './_mixins';
@import 'navbar';
@import 'variables';

.app-header .dropdown-item {
  min-width: unset;
}

.c-header-nav {
  width: 100%;
}

.c-header-nav .dropdown-item {
  min-width: 100px;
}

.c-header-toggler {
  margin-right: 20px;
}

.dropdown-item i {
  margin-right: 10px;
  margin-left: -10px;
}

.form-control,
.form-control:focus {
  font-size: $input-font-size;
  color: $input-font-color;
}

html {
  //removes 300ms touch delay on mobile
  touch-action: manipulation;
}

html,
body {
  min-height: 100vh;
}

.react-select-input {
  font-size: $input-font-size;
}

.ReactTable {
  .rt-table .rt-thead.-header {
    box-shadow: none;
    .rt-tr .rt-th {
      font-weight: bold;
      text-align: left;
      padding: 10px;
    }
  }

  .rt-table .rt-tbody {
    .rt-tr .rt-td {
      padding: 10px;
    }
  }

  .pagination-bottom .-pagination {
    box-shadow: none;
  }
}

.user-email {
  :hover {
    color: black;
    cursor: pointer;
  }
  color: #536c79;
  cursor: pointer;
}

.logo-display {
  max-width: 250px;
  max-height: 250px;
}

.campus-map-display {
  max-width: 250px;
  max-height: 250px;
  margin-bottom: 1.5rem;
}

.crud-table {
  .back {
    margin-bottom: 20px;
    display: inline-block;
  }
  // react table directly sets the style here, making the nuke necessary
  .close-fit-column {
    flex: 1 1 auto !important;
    width: unset !important;
  }
}

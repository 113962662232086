@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_mixins';
@import '~@coreui/coreui/scss/_variables';

//Branding
$fmolhs-7703: rgb(0, 146, 189);
$fmolhs-7684: rgb(56, 94, 157);
$fmolhs-542: rgb(123, 175, 212);
$fmolhs-cool-grey: rgb(175, 179, 192);
$fmolhs-dark-blue: rgb(0, 70, 127);
$fmolhs-dark-blue-disabled: rgb(0, 108, 196);
$fmolhs-dark-blue-active: rgb(2, 88, 158);

//General styles
$input-font-size: 14px;
$input-font-color: #3c4b64;

//Sidebar
//$sidebar-brand-height pulled from coreui variables -- changing the subtracted value will scale the brand image proportionally
$sidebar-brand-image-height: $sidebar-brand-height - 10px;
$collapsed-sidebar-width: 56px;
$sidebar-list-item-height: 48px;

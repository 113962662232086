.card-action-header {
  flex-wrap: wrap;
}

.card-action-header-actions {
  display: flex;
}

.card-action-header-actions > * {
  min-width: 100px;
}

.card-action-header-actions > *:not(:first-child) {
  margin-left: 0.5rem;
}

@import 'styles/custom/_variables';

.c-sidebar {
  background-color: $fmolhs-dark-blue;
  .c-sidebar-nav-link:hover {
    background: $fmolhs-dark-blue-active;
  }
  .c-sidebar-brand {
    background-color: white;
    height: $sidebar-brand-height;
    margin: 0;
    img {
      height: $sidebar-brand-image-height;
    }
  }

  .dropdown-menu {
    //ensure sub menu is touching the sidebar -- eliminates chance of breaking hover
    border-radius: 0px;
    box-shadow: 3px 3px 5px darkgray;
  }
}

.c-sidebar-nav-link.compact-nav-link {
  background-color: #023a68;
  height: $sidebar-list-item-height;
}

.resizing {
  animation: transitionResize 0.15s;
}

@keyframes transitionResize {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

//prevent funky focus outline on the sidebar sub menu element
.sub-menu-header:focus,
.dropdown-menu:focus {
  outline: none;
}

.sidebar-sub-menu:focus,
.sidebar-sub-menu:active {
  outline: none;
  box-shadow: none;
}

.sidebar-sub-menu:hover {
  color: white;
  background-color: $fmolhs-542;
}

.sidebar-sub-menu {
  height: $sidebar-list-item-height;
  color: rgba(255, 255, 255, 0.5);
  color: whitesmoke;
  .c-sidebar-nav-dropdown:hover {
    background-color: transparent;
  }
  .c-sidebar-nav-icon {
    margin: auto !important;
    color: whitesmoke;
  }
  .sidebar-link-show-children {
    position: relative;
    top: 30%;
  }
  background-color: transparent;
  text-align: center;
  border: none;
  width: 100%;
  cursor: pointer;
}

.sub-menu-header {
  margin-left: -10px;
  font-size: 0.875rem;
}

.sidebar-compact {
  .c-sidebar {
    width: $collapsed-sidebar-width;
    flex-basis: $collapsed-sidebar-width;
    .c-sidebar-nav-dropdown span.section-name {
      display: none;
    }
  }
  .c-wrapper {
    .c-header {
      //this avoids any header overlap on the sidebar during sidebar collapse while still allowing the Email dropdown to be over body content
      z-index: 1;
    }
  }
}

.active-indicator {
  position: absolute;
  background-color: slategray;
  width: 3px;
  height: $sidebar-list-item-height;
}

.active-indicator.active {
  background-color: $fmolhs-cool-grey;
}

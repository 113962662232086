@import 'styles/custom/_mixins';

.navbar-toggler,
.user-dropdown,
.c-header {
  transition: box-shadow 0.25s, transform 0.25s, margin-left 0.25s,
    margin-right 0.25s, width 0.25s, z-index 0s ease 0.25s;
}

@include media-breakpoint-up(sm) {
  .user-dropdown .user-email {
    margin-right: map-get($map: $spacers, $key: 4);
  }
}

.sidebar-open {
  @include media-breakpoint-down(md) {
    .c-header {
      margin-left: 256px;
    }
    .user-dropdown {
      margin-right: -50px;
    }
  }
}
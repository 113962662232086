.create-button {
  margin-bottom: 10px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #757575;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.scheduled-prayer-table .pinned-column {
  min-width: 120px;
}

@import 'styles/custom/_mixins';
@import 'styles/custom/_variables';

.formatted-phone-number-cell {
  white-space: nowrap;
}
.table {
  border: 1px solid $gray-200;

  th {
    border-top: none;
    top: $sidebar-brand-height;
    background-color: white;

    &.sort-asc {
      box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
    }

    &.sort-desc {
      box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
    }
  }

  tbody {
    td {
      vertical-align: middle;
    }

    tr:nth-of-type(odd) > td {
      background-color: #f2f2f2;
    }

    tr:nth-of-type(even) > td {
      background-color: white;
    }
  }

  .loading-row,
  .no-data-row {
    td {
      background-color: transparent !important;
    }
    .d-flex {
      width: 100%;
      height: 100%;
    }
    > td {
      .center {
        margin: auto;
        text-align: center;
      }
    }
  }
}

.table-filter-input {
  @extend .form-control;
}

.table-paging {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  .paging {
    flex: 1.5 1;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  button {
    @extend .btn, .btn-light;
    flex: 1 1;
    margin: 2px;
    min-width: 100%;
    @include media-breakpoint-up(sm) {
      min-width: 120px;
    }
  }
  .page-size {
    margin: 2px;
    select {
      @extend .form-control;
    }
  }
  .page-number {
    margin: 2px;
    min-width: 150px;
    display: flex;
    > * {
      margin: auto;
    }
    input {
      @extend .form-control;
      width: 75px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

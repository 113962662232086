.announcements-table {
  thead tr th:nth-child(3) {
    min-width: 120px;
  }
  
  .logo-display-in-table {
    max-width: 100px;
    max-height: 100px;
  }
}
